import {UploadFile, UploadFiles} from "element-plus/lib/components";
import {reactive, toRefs, onBeforeMount, onMounted, getCurrentInstance, provide, nextTick, defineComponent, inject, computed} from 'vue';
import DefineCard from "../../../views/sysviews/flow/define/DefineCard.vue";
import BillEngineUtil,{IBillEngineDataObj,submitData} from "@/components/base_comp/billEngine/billEngineUtil";
import EngineUtil from "@/components/base_comp/cardEngine/engineUtil";
import finish from '../../../assets/flow/finish.png';
export default defineComponent({
    name: 'BillEngine',
    props: {
        //调用dialog的时候外界传给dialog的参数里面有一部分参数会被dialog传给engine组件，详情参看Dialog.vue处
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;//访问全局对象
        provide('uploadOwner', proxy);
        let dialogInst = inject('dialogInst') as any;
        const engineParams=props.engineParams as any;//外界传到引擎的参数（调用dialog组件传入的参数）
        let dataObj:IBillEngineDataObj=reactive<IBillEngineDataObj>({
            utilInst:{} as any,
            engineUtil:{} as any,
            refMap:new Map(),
            loading:false,//整个单据引擎的遮罩层是否显示
            submitDialogVisible:false,//提交弹出框是否显示
            submitDialogLoading:false,//提交弹出框的遮罩层
            rollbackDialogVisible:false,//回滚弹出框是否显示
            rollbackDialogLoading:false,//回滚弹出框的遮罩层
            activeName:'approvalMsg',//单据底部的tab默认选中审批日志tab
            mainMaxHeight:400,//默认内容的最大高度，实际上在mounted中会动态计算出来
            approveLogContentHeight:400,//审批意见默认高度，为了出现滚动条，需要动态计算出审批意见的最大高度
            approveLog:false,//审批日志抽屉显示/影藏控制字段
            reverse:true,//日志排序方式，默认降序排列
            logInfoArr:[],//审批日志
            billInfo:{//单据信息，展示单据的时候会调用addOrLoad方法，该方法会从后台查询信息赋值给它
                id:'',//单据id
                taskUsers:[],//单据下一步骤集合和各个步骤对应的审核人员 [{task:部长审核,users:users,selUser:'',disabled:true,chooseOperator:是否可指定候审人}]
                billBottomInfo:{}//单据底部的信息
            },
            submitData:submitData,//提交、驳回、受理、取消受理都会向后台发送请求，请求的时候都会带上该对象作为参数
            buttons:[],//单据头部按钮
            modelComp:engineParams.modelComp,//本次引擎将包含的组件(外界传入的组件)
            modelCompRef:null,//本次引擎将包含的组件实例ref
            formRef:null,
            form:{},
            otherParams:{
                billStatus:0,
                flowStatus:{
                    display:'block',
                    backgroundImage:'',
                },
                sign:{
                    visible:false,//会签弹出框是否显示
                    dialogLoading:false,//会签弹出框加载控制
                    form:{
                        result:'0',//同意
                        desc:'',//会签信息
                    },
                    signInfo: [],//查看单据详情的时候，后台返回的会签信息
                },
                isEnginePage:false,
                btnLoading:false,
                compParams:{//目前单据还不能采用引擎作为页面，只是把参数写在这里
                    isEnginePage:false,
                    colNum:1,
                    rules:{},
                    disabled:false,
                    hsDetails: false,
                    oneDetail:true,
                    approveLog:{
                        showAttachFile:true,
                        showApprovalMsg:true,
                        showSignInfo:true,
                    }
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.engineUtil=new EngineUtil(dataObj,proxy,utils);
            dataObj.utilInst=new BillEngineUtil(proxy,dataObj,props,context);
        })
        onMounted(async()=>{
            dataObj.loading=true;//打开正在加载效果
            await nextTick(async () => { 
                dataObj.utilInst.initDataObjParams(dialogInst);
                //执行新增或者编辑方法，请求后台，为卡片form表单赋值
                await dataObj.engineUtil.doAddOrLoad(engineParams.id, 'bill');
                await dataObj.engineUtil.animation();
                dataObj.utilInst.initFlowStep();//初始化流程步骤图片
                if(dataObj.otherParams.compParams.detailInfos && Object.keys(dataObj.otherParams.compParams.detailInfos).length>1)dataObj.otherParams.compParams.oneDetail=false;
                dataObj.mainMaxHeight=window.innerHeight-185;
            })
        })
        //按钮事件
        const clickHandler=(ev:string)=>{
            //如果包含的组件有定义该事件，则调用组件自身的该事件，没有定义则采用billEngine中默认定义好的事件
            if (dataObj.modelCompRef[ev]) {
                dataObj.modelCompRef[ev](proxy);
            } else {
                proxy[ev]();
            }
        }

        //保存事件
        const saveHandler=async()=>{
            await dataObj.engineUtil.saveHandler(props.engineParams,'bill',dialogInst)
        }
        //重置事件
        const resetHandler=async()=>{
            await dataObj.engineUtil.resetHandler('bill');
        }
        //选择审批步骤变化事件
        const taskChange=(val:any)=>{
            dataObj.utilInst.doTaskChange(val);
        }
        //保存并提交按钮事件
        const saveSubmitHandler=async()=>{
            if (dataObj.modelCompRef['saveSubmitHandler']) {
                await dataObj.modelCompRef['saveSubmitHandler'](proxy);
            } else {
                await dataObj.utilInst.doSaveSubmit(props.engineParams,dialogInst);
            }
        }
        //提交按钮事件
        const submitHandler=async()=>{
            if (dataObj.modelCompRef['beforeShowSubmitDialog'] && !await dataObj.modelCompRef['beforeShowSubmitDialog'](proxy))return;
            dataObj.submitDialogVisible=true;//打开提交弹出框
        }
        //提交弹出框确认按钮事件
        const submitDialogSure=async()=>{
            dataObj.otherParams.btnLoading=true;
            await dataObj.utilInst.submitDialogSure();
            dataObj.otherParams.btnLoading=false;
        }
        //受理按钮事件
        const claimHandler=()=>{
           dataObj.utilInst.doClaim();
        }
        //取消受理
        const cancelClaimHandler=()=>{
            dataObj.utilInst.doCancelClaim();
        }
        //驳回按钮事件
        const rollBackHandler=async()=>{
            if (dataObj.modelCompRef['beforeShowRollbackDialog'] && !await dataObj.modelCompRef['beforeShowRollbackDialog'](proxy))return;
            dataObj.rollbackDialogVisible=true;
        }
        //驳回弹出框确认事件
        const rollbackDialogSure=async()=>{
            dataObj.otherParams.btnLoading=true;
            await dataObj.utilInst.doRollback();
            dataObj.otherParams.btnLoading=false;
        }
        //取回按钮事件
        const fetchBackHandler=()=>{
            dataObj.utilInst.fetchBack();
        }
        //会签按钮事件
        const signHandler=()=>{
            dataObj.otherParams.sign.visible=true;
        }
        //会签弹出框确认事件
        const signDialogSure=async ()=>{
            await dataObj.utilInst.doSign();
        }
        //作废按钮事件
        const invalidHandle=()=>{
            dataObj.utilInst.doInvalid();
        }
        //查看审批流程图事件
        const flowDesignHandler=async()=>{
            await utils.UtilPub.openDialog({
                title:"流程设计",
                fullscreen:true,
                showFooterBtn:false,
                modelComp:DefineCard,
                proxy:proxy,
                addOrEdit:'edit',
                id:dataObj.submitData.defineId,
                otherParams:{
                    curTask:dataObj.submitData.curTask,
                    isMakeUser:dataObj.billInfo.billBottomInfo.isMakeUser,
                    billId:dataObj.submitData.id,
                    showCb:dataObj.billInfo.billBottomInfo.isMakeUser && false,//是否允许催办
                    // showCb:true//是否允许催办
                }
            });
        }
        //查看审批日志按钮事件
        const approveLogHandler=()=>{
            dataObj.approveLog=true;
        }
        //删除单据事件
        const delHandler=async ()=>{
            await dataObj.engineUtil.delHandler(engineParams,dataObj.submitData.id,dialogInst);
        }

        //删除附件之前（注意返回值是一个promise）
        const beforeRemove=async(file:UploadFile, fileList:UploadFiles)=>{
            if (dataObj.modelCompRef['beforeDeleteAttach'] && ! await dataObj.modelCompRef['beforeDeleteAttach'](file, fileList,proxy))return false;
            return true;
        }
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name,billId: dataObj.submitData.id,billModel:dataObj.submitData.billModel};
                    utils.Api.downLoad(params)
                }
            });
        }
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData:any,params:any,uploadInst:any)=>{
            if((uploadInst.uploadParams.saveType)==0){
                //先删除formData中的这三个key，因为在uploadUtil中调用业务模块的buildUploadParams方法之前，已经为这三个参数赋值了，
                // 如果我们不删除他们，再次为他们赋值，就会在formData中存在两份相同key的属性，到后台拿出来的时候居然是先放进去的，奇怪，所以这里先删除，再放入
                formData.delete('uploadType');
                formData.delete('billModel');
                formData.delete('belongMaxId');
                formData.append('uploadType','billAttach');//上传类别，会建立billAttach文件夹单独存储
                formData.append('billModel',dataObj.submitData.billModel);//单据类别
                formData.append('belongMaxId',dataObj.billInfo.id);//单据id
                //最终文件会存储到：billAttach/单据类别/单据ID/具体文件
            }else{
                formData.belongMaxId=dataObj.billInfo.id;
                formData.type=0;
            }
        }
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return Object.assign({
                    belongMaxId:dataObj.billInfo.id,
                    // saveType:1,
                    type:0,
                    uploadType:'billAttach',
                    showFileList:true,
                },params)
            }
        })
        return{
            ...toRefs(dataObj),clickHandler,saveHandler,resetHandler,taskChange,saveSubmitHandler,
            submitHandler,submitDialogSure,claimHandler,cancelClaimHandler,rollBackHandler,rollbackDialogSure,
            fetchBackHandler,signHandler,flowDesignHandler,invalidHandle,signDialogSure,approveLogHandler,delHandler,
            beforeRemove,handlePreview,buildUploadInitParams,buildUploadParams
        }
    }
});